@import url('./styles-variables.scss');
@import url('./styles-breakpoints.scss');

html,
body {
    margin: 0;
    height: 100%;
    background-color: black;
    user-select: none;

    /* disable blue-highlighting on tap on mobile */
    -webkit-tap-highlight-color: transparent;
}

::selection {
    background-color: var(--color-background-2);
    color: var(--color-text-3);
}

:root {
    --environment-wallpaper-background: url(^assets/wild-environment/wallpapers/grid.png);
    --environment-wallpaper-logo: url(^assets/wild-environment/wallpapers/logo.svg);
}

select {
    background-color: var(--color-background-2);
    color: var(--color-text-0);
    font-size: medium;
    border: none;
}

select:hover {
    border-color: white;
}

select:focus {
    border: none;
    outline: none;
}

select option {
    text-align-last: right;
}

.valid {
    color: var(--color-valid) !important;
}

.invalid {
    color: var(--color-error) !important;
}

.warning {
    background-color: var(--color-warning) !important;
}
